import { InformationCard, Button } from '@yes.technology/react-toolkit'
import { useEffect } from 'react'
import { InformationCard as TInformationCard } from 'types/shared'
import useIsOpen from 'modules/shared/hooks/useIsOpen'
import { SafeHtmlContent } from 'modules/shared/SafeHtmlContent'

type GetCurrentCardParams = {
  cards: TInformationCard[]
  currentStep: number
  isInteractionFinished: boolean
}

const getCurrentCard = ({
  cards,
  currentStep,
  isInteractionFinished
}: GetCurrentCardParams) => {
  if (isInteractionFinished) {
    return cards.find((card) => card.display_on === 'interaction-finished')
  }

  if (currentStep === 1) {
    return cards.find((card) => card.display_on === 'interaction-started')
  }
}

type InteractionInformationProps = {
  cards: TInformationCard[]
  currentStep: number
  isInteractionFinished: boolean
}

export default function InteractionInformation({
  cards = [],
  currentStep,
  isInteractionFinished
}: InteractionInformationProps) {
  const { isOpen, open, close } = useIsOpen(false)

  const currentCard = getCurrentCard({
    cards,
    currentStep,
    isInteractionFinished
  })

  useEffect(() => {
    if (currentCard) {
      open()
    }
  }, [currentCard, open])

  if (!currentCard) {
    return null
  }

  return (
    <InformationCard title='' spacingClass='medium' {...{ isOpen, close }}>
      <div className='d-flex flex-column gap-4 align-items-center mt-1 p-3'>
        {currentCard.image_url && (
          <img
            src={currentCard.image_url}
            alt=''
            width='280'
            height='280'
            className='rounded-circle'
            style={{ border: '2px solid #DDE5ED' }}
          />
        )}
        <h2
          style={{ fontSize: '32px', lineHeight: '48px', fontWeight: 700 }}
          className='mb-0'
        >
          {currentCard.des}
        </h2>
        <span style={{ fontSize: '18px', lineHeight: '24px' }}>
          <SafeHtmlContent html={currentCard.complement} />
        </span>
        {currentCard.action_button && (
          <a href={currentCard.action_button.url} className='col-12'>
            <Button>{currentCard.action_button.des}</Button>
          </a>
        )}
      </div>
    </InformationCard>
  )
}
