import AWS from 'aws-sdk'

// definir parâmetros de acesso ao S3
const S3_BUCKET = window.s3BucketName
const REGION = window.region

AWS.config.update({
  accessKeyId: window.accessKeyId,
  secretAccessKey: window.secretAccessKey
})

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION
})

const useGetObjectS3 = () => {
  const getObject = (filename) => {
    return new Promise((resolve) => {
      myBucket.getObject({ Key: filename }, (err, file) => {
        if (err) {
          console.error('Error fetching S3 object:', err)
          reject(err)
          return
        }

        const params = {
          contentLength: file.ContentLength,
          contentType: file.ContentType,
          body: file.Body
        }

        // Se o objeto for uma imagem, converte para base64
        if (file.ContentType.startsWith('image/')) {
          params.image = `data:${file.ContentType};base64,${encode(file.Body)}`
        }

        resolve(params)
      })
    })
  }
  return {
    getObject
  }
}

function encode(data) {
  let buf = Buffer.from(data)
  let base64 = buf.toString('base64')
  return base64
}

export default useGetObjectS3
