import { DatePicker } from '@yes.technology/react-toolkit'

const ActivityInputDate = ({ minimumYear, maximumYear, ...props }) => {
  const currentYear = new Date().getFullYear()
  const defaultMinYear = currentYear - 100
  const defaultMaxYear = currentYear + 100

  const intMinYear = parseInt(minimumYear)
  const intMaxYear = parseInt(maximumYear)

  return (
    <DatePicker
      {...props}
      isLoadingBlueprints={props.isFetchingBlueprints}
      heightClass='medium'
      minimumYear={intMinYear || defaultMinYear}
      maximumYear={intMaxYear || defaultMaxYear}
      value={props.value || ''}
    />
  )
}

export default ActivityInputDate
