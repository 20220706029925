import { InteractionModel } from 'types/shared'
import useWorkflow from './useWorkflow'
import { isEmbedded, useParentPostMessage } from '@yes.technology/react-toolkit'
import useUpgradeToken from './useUpgradeToken'
import { ExecutionState } from 'types/workflow'

type AutomationTrigger = 'submit-success'

type RedirectAutomationData = {
  url_source: string
  url_identifier: string
}

type UpgradeTokenAutomationData = {
  user_uuid_source: string
  user_uuid_identifier?: string
  organization_uuid_source: string
  organization_uuid_identifier?: string
  applicationsystem_uuid_source: string
  applicationsystem_uuid_identifier?: string
}

type CommonAutomation = {
  uuid: string
  des: string
  trigger: AutomationTrigger
}

type RedirectAutomation = CommonAutomation & {
  automation_type: 'redirect'
  automation_data: RedirectAutomationData
}

type UpgradeTokenAutomation = CommonAutomation & {
  automation_type: 'upgrade-token'
  automation_data: UpgradeTokenAutomationData
}

export type Automation = RedirectAutomation | UpgradeTokenAutomation

const useAutomation = ({
  interactionModel
}: {
  interactionModel: InteractionModel
}) => {
  const upgradeToken = useUpgradeToken()

  const { workflow } = useWorkflow({
    workflowUuid: interactionModel.workflowUuid
  })

  const { postMessage } = useParentPostMessage()

  const getDataFromSource = (source: string, identifier?: string) => {
    switch (source) {
      case 'current':
        return undefined
      case 'static':
        return identifier
      case 'workflow_data_container':
        const executionState: ExecutionState =
          JSON.parse(workflow?.execution_state || '{}') || {}

        return executionState.data_container[identifier || '']
    }
  }

  const executeUpgradeTokenAutomation = async (
    automation: UpgradeTokenAutomation
  ) => {
    const {
      user_uuid_source,
      user_uuid_identifier,
      organization_uuid_source,
      organization_uuid_identifier,
      applicationsystem_uuid_source,
      applicationsystem_uuid_identifier
    } = automation.automation_data

    const userUuid = getDataFromSource(user_uuid_source, user_uuid_identifier)

    const organizationUuid = getDataFromSource(
      organization_uuid_source,
      organization_uuid_identifier
    )
    const applicationSystemUuid = getDataFromSource(
      applicationsystem_uuid_source,
      applicationsystem_uuid_identifier
    )

    if (!userUuid && !organizationUuid && !applicationSystemUuid) {
      console.error(`Data not found for automation ${automation.uuid}`)
      return
    }

    await upgradeToken({
      ...(userUuid && { user_uuid: userUuid }),
      ...(organizationUuid && { organization_uuid: organizationUuid }),
      ...(applicationSystemUuid && {
        applicationsystem_uuid: applicationSystemUuid
      })
    })
  }

  const executeRedirectAutomation = (automation: RedirectAutomation) => {
    const { url_source, url_identifier } = automation.automation_data

    const url = getDataFromSource(url_source, url_identifier)

    if (!url) {
      console.error(`URL not found for automation ${automation.uuid}`)
      return
    }

    if (isEmbedded()) {
      postMessage({ type: 'redirect', url })
    } else {
      window.location.href = url
    }
  }

  const executeAutomation = async (automation: Automation) => {
    switch (automation.automation_type) {
      case 'upgrade-token':
        await executeUpgradeTokenAutomation(automation)
        break
      case 'redirect':
        executeRedirectAutomation(automation)
        break
    }
  }

  return executeAutomation
}

export default useAutomation
