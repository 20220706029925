import { AutocompleteMultiselection } from '@yes.technology/react-toolkit'
import { useCallback } from 'react'

type SelectionMenuOptions = React.ComponentProps<
  typeof AutocompleteMultiselection
>['options']

export type UseMultiselectionOnSelectProps = {
  selectedOptions: SelectionMenuOptions
  onChange: (value: { id: string; des: string }) => void
}

export default function useMultiselectionOnSelect({
  selectedOptions,
  onChange
}: UseMultiselectionOnSelectProps) {
  return useCallback(
    (
      targetSelection: SelectionMenuOptions | SelectionMenuOptions[0],
      selectOnly = false
    ) => {
      const targetSelections = Array.isArray(targetSelection)
        ? targetSelection
        : [targetSelection]

      const selectedOptionsIdMap = Object.fromEntries(
        selectedOptions.map((option) => [option.id, true])
      )
      const targetSelectionsIdMap = Object.fromEntries(
        targetSelections.map((selection) => [selection.id, true])
      )

      const optionsAfterDeselection = selectOnly
        ? selectedOptions
        : selectedOptions.filter((option) => !targetSelectionsIdMap[option.id])

      const newSelectedOptions = [
        ...optionsAfterDeselection,
        ...targetSelections.filter(
          (selection) => !selectedOptionsIdMap[selection.id]
        )
      ]

      const uuids = newSelectedOptions.map((option) => option.id)
      const descriptions = newSelectedOptions.map((option) => option.value)

      onChange({ id: uuids.join(', '), des: descriptions.join(', ') })
    },
    [onChange, selectedOptions]
  )
}
