import { useDispatch } from 'react-redux'
import { interactionModelOperations } from '../redux'
import { deepClone } from 'utils/helpers/object'
import {
  Activity,
  ActivityModel,
  InteractionModelItemGroup,
  SequenceDecisionModel
} from 'types/shared'

const useSequenceRules = () => {
  const dispatch = useDispatch()

  const hideStep = (
    stepUuids: string[],
    itemGroups: InteractionModelItemGroup[]
  ) => {
    itemGroups.forEach((itemGroup) => {
      if (stepUuids.includes(itemGroup.uuid)) {
        itemGroup.show = false
      }
    })
  }

  const showStep = (
    stepUuids: string[],
    itemGroups: InteractionModelItemGroup[]
  ) => {
    itemGroups.forEach((itemGroup) => {
      if (stepUuids.includes(itemGroup.uuid)) {
        itemGroup.show = true
      }
    })
  }

  const handleActions = (
    actionsList: SequenceDecisionModel[keyof SequenceDecisionModel][],
    itemGroups: InteractionModelItemGroup[]
  ) => {
    if (actionsList.length === 0) {
      return
    }

    const itemGroupsClone = deepClone(itemGroups)

    actionsList.forEach((actions) => {
      Array.isArray(actions) &&
        actions.forEach((action) => {
          if (action.action === 'hide_step')
            hideStep(action.step_uuids, itemGroupsClone)
        })
    })

    actionsList.forEach((actions) => {
      Array.isArray(actions) &&
        actions.forEach((action) => {
          if (action.action === 'show_step')
            showStep(action.step_uuids, itemGroupsClone)
        })
    })

    dispatch(interactionModelOperations.updateItemGroups(itemGroupsClone))
  }

  const applyInitialDecisionModel = (
    activites: Activity[],
    itemGroups: InteractionModelItemGroup[]
  ) => {
    const activityMap: Record<string, ActivityModel> = {}

    itemGroups.forEach((itemGroup) =>
      itemGroup.activity_models.forEach(
        (activityModel) => (activityMap[activityModel.uuid] = activityModel)
      )
    )

    const actionsList: SequenceDecisionModel[keyof SequenceDecisionModel][] = []

    activites.forEach((activity) => {
      const belongingActivityModel = activityMap[activity.uuid_activity_model]

      if (!belongingActivityModel) return

      activity.des
        .split(',')
        .forEach(
          (uuid) =>
            belongingActivityModel?.sequence_decision_model &&
            actionsList.push(
              belongingActivityModel?.sequence_decision_model?.[uuid.trim()]
            )
        )
    })

    handleActions(actionsList, itemGroups)
  }

  const executeSequenceDecisionModel = (
    currentActivity: Activity,
    currentActivityModels: ActivityModel[],
    itemGroups: InteractionModelItemGroup[]
  ) => {
    const actionsList: SequenceDecisionModel[keyof SequenceDecisionModel][] = []

    currentActivityModels.forEach((activityModel) => {
      if (
        currentActivity.uuid_activity_model === activityModel.uuid &&
        activityModel?.sequence_decision_model
      ) {
        String(currentActivity.des)
          .split(',')
          .forEach(
            (uuid) =>
              activityModel?.sequence_decision_model &&
              actionsList.push(
                activityModel?.sequence_decision_model?.[uuid.trim()]
              )
          )
      }
    })
    handleActions(actionsList, itemGroups)
  }

  return {
    executeSequenceDecisionModel,
    applyInitialDecisionModel
  }
}

export default useSequenceRules
