import { ActivityModel } from 'types/shared'
import ActivityDocumentPhoto from './ActivityDocumentPhoto'
import ActivityValue from './ActivityValue'
import ActivityDocumentFile from './ActivityDocumentFile'
import ActivitySafeHTML from './ActivitySafeHTML'

interface ActivityProps {
  activityModels: ActivityModel[]
}

const Activity = ({ activityModels }: ActivityProps) => {
  return (
    <>
      {activityModels?.map((activity) => {
        const isActivityGroup = activity.content_type === 'activity_group'
        const isDocumentActivity =
          activity.content_type === 'document_photo' ||
          activity.content_type === 'document_file'

        return (
          <div key={activity.uuid}>
            {activity.content_type === 'safe_html' && (
              <ActivitySafeHTML htmlContent={activity.html_content} />
            )}

            {activity?.value && (
              <>
                {activity.content_type === 'document_photo' && (
                  <ActivityDocumentPhoto values={activity.value as string} />
                )}

                {activity.content_type === 'document_file' && (
                  <ActivityDocumentFile
                    values={activity.value as string}
                    label={activity.display_text.des}
                  />
                )}

                {!isDocumentActivity && (
                  <ActivityValue
                    uuid={activity.uuid}
                    label={activity.display_text.des}
                    value={activity.value}
                    contentType={activity.content_type}
                    required={activity.required}
                  />
                )}
              </>
            )}

            {isActivityGroup &&
              activity.activity_models &&
              activity.activity_models.map((activityGroup, index) => {
                return (
                  <div key={index} className='mb-2'>
                    <div className='card-body'>
                      <h2>{activity.display_text.des}</h2>
                      <Activity activityModels={activityGroup} />
                    </div>
                  </div>
                )
              })}
          </div>
        )
      })}
    </>
  )
}

export default Activity
