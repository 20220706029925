import { useTranslation } from 'react-i18n-lite'
import { StandardUniselection } from '@yes.technology/react-toolkit'

function ActivityInputSelect({
  id,
  inputValue,
  options,
  onChange,
  label,
  readOnly,
  required,
  helpText,
  explanationText,
  statusClass,
  isFetchingBlueprints = false
}) {
  const { t } = useTranslation()

  const handleChange = (item) => {
    onChange(item.value)
  }

  const formatValue = (value) => ({
    id: value,
    value: value
  })

  const getSelected = () => {
    const selectedOption = options.filter((item) => item === inputValue)[0]
    return selectedOption ? formatValue(selectedOption) : undefined
  }

  const loadingStatusClass = isFetchingBlueprints ? 'processing' : undefined

  return (
    <StandardUniselection
      id={id}
      label={label}
      heightClass='medium'
      options={options.map(formatValue)}
      selectedOption={getSelected()}
      required={required}
      onSelect={handleChange}
      placeholder={helpText || explanationText || t('form.option.select')}
      informational={readOnly}
      statusClass={loadingStatusClass || statusClass}
    />
  )
}

export default ActivityInputSelect
