import { SafeHtmlContent } from 'modules/shared/SafeHtmlContent'

type ActivitySafeHTMLProps = {
  htmlContent?: string
}

const ActivitySafeHTML = ({ htmlContent }: ActivitySafeHTMLProps) => {
  return htmlContent ? <SafeHtmlContent html={htmlContent} /> : null
}

export default ActivitySafeHTML
