import { ExplicitSelectionAccordion } from '@yes.technology/react-toolkit'
import useFetchSuggestions from 'modules/interaction/hooks/useFetchSuggestions'
import { useEffect } from 'react'
import { ActivityModel } from 'types/shared'
import { FilteractionParams } from 'utils/apiTypes'
import { ActivityInputProps } from '../Activity.types'

export type ActivityMultiselectionAccordionProps = {
  id: string
  filteraction?: string
  filteractionParams?: FilteractionParams[]
  value: ActivityModel['value']
  onChange: ActivityInputProps['handleChange']
} & Pick<
  React.ComponentProps<typeof ExplicitSelectionAccordion>,
  'hideAccordionArrowOnDisable' | 'options' | 'required'
>

const ActivityMultiselectionAccordion = ({
  id,
  filteraction,
  value,
  options = [],
  onChange,
  required,
  hideAccordionArrowOnDisable,
  filteractionParams
}: ActivityMultiselectionAccordionProps) => {
  const { suggestions, fetchSuggestions } = useFetchSuggestions({
    id,
    filteraction,
    filteractionParams,
    showLoading: true
  })

  useEffect(() => {
    if (!filteraction) {
      return
    }

    fetchSuggestions('.*')
  }, [fetchSuggestions, filteraction])

  const finalOptions = options.length ? options : suggestions
  const selectedOptions = {
    des: (typeof value === 'object' && value?.des?.split(', ')) || [],
    id: (typeof value === 'object' && value?.id?.split(', ')) || []
  }

  const getCheckedOptions = () => {
    let selectedOption: (typeof options)[0] | undefined

    return selectedOptions.id.reduce(
      (accumulator: Record<string, true>, current) => {
        selectedOption = finalOptions.filter(
          (option) => option.uuid === current
        )[0]

        if (selectedOption) {
          accumulator[selectedOption.uuid] = true
        }

        return accumulator
      },
      {}
    )
  }

  const handleChange = ({ des, uuid }: { des: string; uuid: string }) => {
    const index = selectedOptions.id.indexOf(uuid)

    if (index === -1) {
      selectedOptions.des.push(des)
      selectedOptions.id.push(uuid)
    } else {
      selectedOptions.des.splice(index, 1)
      selectedOptions.id.splice(index, 1)
    }

    onChange({
      id: selectedOptions.id.join(', '),
      des: selectedOptions.des.join(', ')
    })
  }

  const checkedOptions = getCheckedOptions()

  return (
    <ExplicitSelectionAccordion
      variant='multiselection'
      options={finalOptions}
      optionLabelsPosition={'right'}
      id={id}
      required={required}
      onChange={handleChange}
      checkedOption={checkedOptions}
      hideAccordionArrowOnDisable={hideAccordionArrowOnDisable}
      textContentVariant='bordertext-04'
    />
  )
}

export default ActivityMultiselectionAccordion
