import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18n-lite'
import {
  Button,
  DocumentListCard,
  Unifree
} from '@yes.technology/react-toolkit'

import ItemGroup from './components/ItemGroup'
import { useInteraction } from './hook'
import InteractionData from './components/InteractionData'
import GoToTop from './components/GoToTop/GoToTop'
import { useReviewSaveButtonContext } from './components/ReviewSaveButton/ReviewSaveButtonContext'

const useToggleStickySave = () => {
  const staticSaveRef = useRef<HTMLDivElement>(null)
  const { setStickySaveVisible } = useReviewSaveButtonContext()

  useEffect(() => {
    if (!staticSaveRef.current) {
      return
    }

    // show sticky save button if static save button is not fully visible
    const staticSaveObserver = new IntersectionObserver(
      (entries) => {
        setStickySaveVisible(entries[0].intersectionRatio !== 1)
      },
      { threshold: 1 }
    )

    const staticSaveButton = staticSaveRef.current
    staticSaveObserver.observe(staticSaveButton)

    return () => {
      staticSaveObserver.unobserve(staticSaveButton)
    }
  }, [setStickySaveVisible])

  return {
    staticSaveRef
  }
}

interface ReviewProps {
  onSave: () => void
  goToStep: (step: number) => void
}

const Review = ({ onSave, goToStep }: ReviewProps) => {
  const { t } = useTranslation()
  const { interactionModelItemGroups, user, dateStartInteraction } =
    useInteraction()

  const [currentCoords, setCurrentCoords] = useState<GeolocationCoordinates>()

  const { staticSaveRef } = useToggleStickySave()

  return (
    <div className='position-relative pb-5'>
      <DocumentListCard className='mt-2'>
        <Unifree
          disabled
          heightClass='large'
          label={t('step.review.status')}
          // TODO: Remove hardcoded value
          value={t('step.review.waiting-activation')}
          required
        />
      </DocumentListCard>

      <DocumentListCard className='mt-2'>
        <Unifree
          disabled
          heightClass='large'
          // TODO: Update textg to 'ID | Situation | Version' in i18n's json
          label={t('step.review.id-situation-version')}
          // TODO: Remove hardcoded value
          value={t('step.review.in-insertion')}
          required
        />
        <div ref={staticSaveRef}>
          <Button
            className='mt-3'
            onClick={onSave}
            impactClass='situationChange'
          >
            {t('step.review.save-and-activate')}
          </Button>
        </div>
      </DocumentListCard>

      <InteractionData
        coords={currentCoords}
        onCoordsChange={setCurrentCoords}
        dateStartInteraction={dateStartInteraction}
        user={user}
      />

      <ItemGroup itemGroups={interactionModelItemGroups} goToStep={goToStep} />

      <GoToTop />
    </div>
  )
}

export default Review
