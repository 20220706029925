import debounce from 'lodash.debounce'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import api from '../../../utils/api'
import { VALIDATION_STATUSES } from '../../../utils/constants'
import { interactionModelOperations } from '../redux'

const { updateActivityModelStatus } = interactionModelOperations

export default function useValidationCallbacks(interactionModel) {
  const dispatch = useDispatch()

  const validateUsingCallback = async (activityModel) => {
    if (
      !activityModel.validation_activity_callback ||
      activityModel.value === ''
    ) {
      return { validationStatus: VALIDATION_STATUSES.PENDING, helperText: '' }
    }

    const reqObj = {
      content: activityModel.value
    }

    const validationCallback = activityModel.validation_activity_callback

    const onError = (e) => ({ success: false, message: e.message })

    const { success, message } = await api
      .postInputValidation(reqObj, validationCallback, false)
      .catch(onError)
    const validMessage = 'activity-input.validation.is_valid'

    return {
      validationStatus: success
        ? VALIDATION_STATUSES.VALID
        : VALIDATION_STATUSES.INVALID,
      helperText: success ? validMessage : message || ''
    }
  }

  const callPendingValidationCallbacks = useCallback(
    (oldInteractionModel) => {
      const steps = oldInteractionModel?.interaction_model_item_groups || []

      steps.forEach((step) => {
        step.activity_models.forEach((activityModel) => {
          if (activityModel?.validationStatus === VALIDATION_STATUSES.PENDING) {
            validateUsingCallback(activityModel).then(
              ({ validationStatus, helperText } = {}) => {
                dispatch(
                  updateActivityModelStatus({
                    activityModelUuid: activityModel.uuid,
                    validationStatus,
                    helperText
                  })
                )
              }
            )
          }
        })
      })
    },
    [dispatch]
  )

  const debouncedValidationsCaller = useMemo(
    () => debounce(callPendingValidationCallbacks, 500),
    [callPendingValidationCallbacks]
  )

  useEffect(() => {
    debouncedValidationsCaller(interactionModel)
  }, [debouncedValidationsCaller, interactionModel])
}
