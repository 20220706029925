import { useState, useMemo } from 'react'

import { AutocompleteMultiselection } from '@yes.technology/react-toolkit'

import useActions from 'modules/interaction/hooks/useActions'
import useFetchSuggestions from 'modules/interaction/hooks/useFetchSuggestions'

import ActivityWithActions from '../ActivityWithActions/ActivityWithActions'

import useMultiselectionOnSelect from './useMultiselectionOnSelect'

const getOptions = ({ selectedOptions, suggestions }) => {
  const mappedSuggestions = suggestions.map(({ uuid, des }) => ({
    id: uuid,
    value: des
  }))

  const uniqueSelectedOptions = selectedOptions.filter(
    (selectedOption) =>
      !suggestions.find((suggestion) => suggestion.uuid === selectedOption.id)
  )

  const options = [...uniqueSelectedOptions, ...mappedSuggestions]

  return options
}

const getSelectedOptions = (value) => {
  if (!value || typeof value.id !== 'string') {
    return []
  }

  const uuids = value.id.split(', ')
  const descriptions = value.des.split(', ')

  const selectedOptions = uuids.map((uuid, index) => ({
    id: uuid,
    value: descriptions[index]
  }))

  return selectedOptions
}

const ActivityAutocompleteMultiselection = ({
  id,
  filteraction,
  filteractionParams,
  value,
  onChange,
  endpoint,
  statusClass,
  isFetchingBlueprints = false,
  actions = [],
  ...rest
}) => {
  const [inputValue, setInputValue] = useState('')

  const selectedOptions = useMemo(() => getSelectedOptions(value), [value])

  const {
    suggestions,
    debouncedFetchSuggestions,
    resetSuggestions,
    isLoading: isLoadingSuggestions
  } = useFetchSuggestions({ id, filteraction, filteractionParams, endpoint })

  const options = useMemo(
    () => getOptions({ selectedOptions, suggestions }),
    [selectedOptions, suggestions]
  )

  const onSelect = useMultiselectionOnSelect({ selectedOptions, onChange })

  const handleOnChange = (value) => {
    if (value === '') {
      resetSuggestions()
    } else {
      debouncedFetchSuggestions(value)
    }

    setInputValue(value)
  }

  const { onClickAction, isLoadingFilteraction } = useActions({
    actions,
    filteraction,
    onSelectAndLink: (values) => {
      onSelect(
        values.map((value) => ({
          id: value.uuid,
          value: value.des
        })),
        true
      )
    }
  })

  const isLoading = isLoadingFilteraction || isLoadingSuggestions

  return (
    <ActivityWithActions {...{ actions, onClickAction }}>
      <AutocompleteMultiselection
        {...rest}
        statusClass={isLoading ? 'processing' : statusClass}
        onChange={handleOnChange}
        value={inputValue}
        isLoadingBlueprints={isFetchingBlueprints}
        {...{ options, selectedOptions, onSelect }}
      />
    </ActivityWithActions>
  )
}

export default ActivityAutocompleteMultiselection
