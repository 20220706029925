import useStatus from './useStatus'
import useSituation from './useSituation'
import api from '../../../utils/api'

const idObjectClassStorageUrl = window.urlObjectclassUuid
const idObjectClassDocument = window.documentObjectclassUuid

const useObjects = () => {
  const { objectStatus: statusStorageUrl } = useStatus({
    objectClass: idObjectClassStorageUrl
  })

  const { visibleSituationActions: visibleSituationActionsStorageUrl } =
    useSituation({ objectClass: idObjectClassStorageUrl })

  const { objectStatus: statusDocument } = useStatus({
    objectClass: idObjectClassDocument
  })

  const { visibleSituationActions: visibleSituationActionsDocument } =
    useSituation({ objectClass: idObjectClassDocument })

  const createOrUpdateDocument = async (obj) => {
    let url
    if (obj?.url) {
      const payloadStorageUrl = {
        situation:
          visibleSituationActionsStorageUrl[0]?.destination_situation?.[0]
            ?.code,
        uuid_status: statusStorageUrl?.code,
        des: obj.url,
        uuid_Objectclass: idObjectClassStorageUrl
      }
      const storageUrl = await api.postObjects(payloadStorageUrl, true)
      url = { url: [{ uuid: storageUrl?.data?.uuid }] }
    }

    if (obj?.uuid) {
      const object = await api.postObjectsFilter({
        objectclass: idObjectClassDocument,
        query: {
          'uuid.des': {
            $eq: obj.uuid
          }
        }
      })
      await api.patchObjects(
        {
          uuid: obj.uuid,
          uuid_Objectclass: object?.uuid_Objectclass,
          version: object?.data?.objects?.[0]?.version,
          ...obj,
          ...url
        },
        obj?.uuid,
        true
      )
      return {
        document: object?.data?.objects?.[0],
        statusDes: statusDocument?.des
      }
    } else {
      const payloadDocument = {
        situation:
          visibleSituationActionsDocument[0]?.destination_situation?.[0]?.code,
        uuid_status: statusDocument?.code,
        uuid_Objectclass: idObjectClassDocument,
        ...obj,
        ...url
      }

      const documentData = await api.postObjects(payloadDocument, true)

      return {
        document: documentData?.data,
        statusDes: statusDocument?.des
      }
    }
  }

  return {
    createOrUpdateDocument
  }
}

export default useObjects
