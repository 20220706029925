import { useTranslation } from 'react-i18n-lite'
import ActivityValue from './ActivityValue'
import { formatFileSize } from 'modules/shared/utils/formatFileSize'
import { Col, Row, Text } from '@yes.technology/react-toolkit'

interface ActivityDocumentFileProps {
  values: string
  label: string
}

const ActivityDocumentFile = ({ values, label }: ActivityDocumentFileProps) => {
  const { t } = useTranslation()
  const obj = JSON.parse(values)
  return (
    <>
      {label && (
        <Row className='mb-2'>
          <Col lg={12}>
            <Text as='label' variant='label-03'>
              {label}
            </Text>
          </Col>
        </Row>
      )}

      <ActivityValue
        label={t('activity-document-file.labels.des')}
        value={obj.des}
      />
      <ActivityValue
        label={t('activity-document-file.labels.document-id')}
        value={obj.uuid}
      />
      <ActivityValue
        label={t('activity-document-file.labels.status')}
        value={obj.uuid_status}
      />
      <div className='row'>
        <div className='col-6'>
          <ActivityValue
            label={t('activity-document-file.labels.content-type')}
            value={obj.contentType}
          />
        </div>
        <div className='col-6'>
          <ActivityValue
            label={t('activity-document-file.labels.size')}
            value={obj.size ? formatFileSize(Number(obj.size)) : ''}
          />
        </div>
      </div>
      <ActivityValue
        label={t('activity-document-file.labels.file-name')}
        value={obj.originalFilename}
      />
    </>
  )
}

export default ActivityDocumentFile
