import styled from 'styled-components'

export const Container = styled.div`
  position: relative;

  > div + div {
    height: 1px;
    position: absolute;
    visibility: hidden;
    top: 0;
    z-index: -1;
  }
`
