import { useQuery } from '@tanstack/react-query'
import { fetchFilteraction } from 'modules/imported/FilteractionResolver'

export default function useFetchFilteraction(
  filteractionId: string,
  options: { enabled?: boolean }
) {
  return useQuery({
    queryKey: ['fetchFilteraction', filteractionId],
    queryFn: () => fetchFilteraction(filteractionId),
    ...options
  })
}
