import { useEffect, useState } from 'react'

import { AutocompleteUniselection } from '@yes.technology/react-toolkit'

import useActions from 'modules/interaction/hooks/useActions'
import useFetchSuggestions from 'modules/interaction/hooks/useFetchSuggestions'

import ActivityWithActions from '../ActivityWithActions/ActivityWithActions'

const getOptions = (options) => {
  if (!Array.isArray(options)) return []

  return options.every(
    ({ des, uuid }) => des !== undefined && uuid !== undefined
  )
    ? options.map(({ des: value, uuid: id }) => ({ id, value }))
    : []
}

const ActivityAutocompleteUniselection = ({
  id,
  filteraction,
  filteractionParams,
  onChange,
  value: selectedOption,
  endpoint,
  statusClass,
  isFetchingBlueprints = false,
  actions = [],
  ...props
}) => {
  const [inputValue, setInputValue] = useState(selectedOption?.des || '')

  const {
    suggestions,
    setSuggestions,
    isLoading: isLoadingSuggestions,
    debouncedFetchSuggestions,
    resetSuggestions
  } = useFetchSuggestions({ id, filteraction, filteractionParams, endpoint })

  const options = getOptions(suggestions)

  const handleOnChange = (targetSelection) => {
    setInputValue(targetSelection)
    if (targetSelection === '') {
      resetSuggestions()
      onChange('')
      return
    }

    // todo: refactor this to use a uuid instead of a description
    const optionFound = options.find(
      (option) => option.value === targetSelection
    )

    if (!optionFound) {
      debouncedFetchSuggestions(targetSelection)
      return
    }

    onChange({ id: optionFound.id, des: optionFound.value })
  }

  const updateInputValueFromProp = () => {
    if (!selectedOption?.des) {
      return
    }

    setInputValue(selectedOption.des)
  }

  useEffect(updateInputValueFromProp, [selectedOption?.des])

  useEffect(() => {
    setSuggestions([{ des: selectedOption?.des, uuid: selectedOption?.id }])
  }, [selectedOption, setSuggestions])

  const { isLoadingFilteraction, onClickAction } = useActions({
    actions,
    filteraction,
    onSelectAndLink: ([value]) => {
      handleOnChange(value.des)

      onChange({
        id: value.uuid,
        des: value.des
      })
    }
  })

  const isLoading = isLoadingFilteraction || isLoadingSuggestions

  return (
    <ActivityWithActions
      actions={actions}
      onClickAction={onClickAction}
      uniselection={true}
    >
      <AutocompleteUniselection
        {...props}
        statusClass={isLoading ? 'processing' : statusClass}
        onChange={handleOnChange}
        isLoadingBlueprints={isFetchingBlueprints}
        value={inputValue}
        {...{ options }}
      />
    </ActivityWithActions>
  )
}

export default ActivityAutocompleteUniselection
