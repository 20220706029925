import { useTranslation } from 'react-i18n-lite'
import { Button, Icon, SyntheticButton } from '@yes.technology/react-toolkit'

import { Container } from './ActivityWithActions.styles'

const ALLOWED_ACTIONS = ['select_and_link'] as const

type AllowedAction = (typeof ALLOWED_ACTIONS)[number]

const ACTION_ICONS: Record<
  AllowedAction,
  React.ComponentProps<typeof Icon>['iconName']
> = {
  select_and_link: 'SelectAndRelateSign'
}

type ButtonVariant = 'primary' | 'secondary'

export type ActivityWithActionsProps = React.PropsWithChildren<{
  actions: string[]
  onClickAction: (action: string, uniselection?: boolean) => void
  uniselection?: boolean
  variant?: ButtonVariant
}>

export default function ActivityWithActions({
  children,
  actions,
  onClickAction,
  uniselection,
  variant = 'secondary'
}: ActivityWithActionsProps) {
  const { t } = useTranslation()

  const filteredAllowedActions = actions.filter(
    (action): action is AllowedAction =>
      ALLOWED_ACTIONS.includes(action as AllowedAction)
  )

  if (!filteredAllowedActions.length) {
    return children
  }

  const renderSingleActionButton = (action: AllowedAction) => (
    <Button
      variant={variant}
      title={t(`activity-input.actions.labels.${action}`)}
      onClick={() => onClickAction(action, uniselection)}
    >
      <Icon iconName={ACTION_ICONS[action]} />
    </Button>
  )

  const renderMultipleActionsButton = () => (
    <SyntheticButton
      variant={variant}
      options={filteredAllowedActions.map((action) => ({
        title: t(`activity-input.actions.labels.${action}`),
        children: <Icon iconName={ACTION_ICONS[action]} />,
        onClick: () => onClickAction(action, uniselection)
      }))}
      dropdownLabel={t('activity-input.actions.more')}
    />
  )

  return (
    <Container>
      {children}
      {filteredAllowedActions.length === 1
        ? renderSingleActionButton(filteredAllowedActions[0])
        : renderMultipleActionsButton()}
    </Container>
  )
}
