import useFetchFilteraction from 'modules/shared/hooks/useFetchFilteraction'

import {
  SelectAndLinkData,
  useSelectAndLinkFrameContext
} from '../components/SelectAndLinkFrame/SelectAndLinkFrameContext'

export const SELECT_AND_LINK_ACTION = 'select_and_link'

type UseActionsProps = {
  filteraction?: string
  actions: string[]
  onSelectAndLink: SelectAndLinkData['callback']
}

export default function useActions({
  filteraction,
  onSelectAndLink,
  actions
}: UseActionsProps) {
  const { setSelectAndLinkData } = useSelectAndLinkFrameContext()

  const { data, isLoading: isLoadingFilteraction } = useFetchFilteraction(
    filteraction || '',
    { enabled: !!filteraction && actions.includes(SELECT_AND_LINK_ACTION) }
  )

  const uuidObjectclass = data?.filter_action_objectclass?.[0].uuid

  const onClickAction = (action: string, uniselection?: boolean) => {
    if (action === SELECT_AND_LINK_ACTION) {
      setSelectAndLinkData({
        uuidObjectclass,
        callback: onSelectAndLink,
        uniselection
      })
    }
  }

  return {
    onClickAction,
    isLoadingFilteraction
  }
}
