import { OneTimePassword } from '@yes.technology/react-toolkit'
import { ActivityModel } from 'types/shared'
import { ActivityInputProps } from '../Activity.types'

const EMPTY_DIGIT_PLACEHOLDER = ' '

type ActivityOTP = {
  value: ActivityModel['value']
  onChange: ActivityInputProps['handleChange']
} & Pick<
  React.ComponentProps<typeof OneTimePassword>,
  'id' | 'label' | 'required' | 'statusClass' | 'length'
>

const ActivityOTP = ({ value, onChange, length, ...props }: ActivityOTP) => {
  const formattedValues = Array.from(
    { length: length || 5 },
    (param, index) => {
      const digit = (typeof value === 'object' ? value.des : value)?.[index]
      return !digit || digit === EMPTY_DIGIT_PLACEHOLDER ? '' : digit
    }
  )

  const handleChange = (value: string, index: number) => {
    formattedValues[index] = value
    onChange(
      formattedValues
        .map((formattedValue) => formattedValue || EMPTY_DIGIT_PLACEHOLDER)
        .join('')
    )
  }

  return (
    <OneTimePassword
      heightClass='medium'
      values={formattedValues}
      onChange={handleChange}
      length={length}
      {...props}
    />
  )
}

export default ActivityOTP
