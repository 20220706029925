import { useState } from 'react'
import AWS, { AWSError } from 'aws-sdk'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18n-lite'

import { videoTypes } from '../../shared/WebcamStreamCapture/components/Player'

// definir parâmetros de acesso ao S3
const S3_BUCKET = window.s3BucketName
const REGION = window.region

AWS.config.update({
  accessKeyId: window.accessKeyId,
  secretAccessKey: window.secretAccessKey
})

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION
})

// S3 não informa a url final do arquivo, então montei aqui
const URL = `https://${S3_BUCKET}.s3.amazonaws.com/`

type UploadFileProps = {
  file: string | File
  fileType?: string
  onStart?: () => void
  onSuccess?: (url: string, key: string, filename: string) => void
  onError?: (error: AWSError) => void
}

type UploadRequestParams = Awaited<
  ReturnType<
    | typeof generateBase64ImageUploadParams
    | typeof generateBlobVideoUploadParams
    | typeof generateDefaultUploadParams
  >
>

const useUploadS3 = () => {
  const { t } = useTranslation()

  // ainda não é permitido a escolha do tipo de vídeo, mas já estou abrindo o caminho
  const [videoType] = useState(videoTypes[0])

  const [progress, setProgress] = useState(0)
  const [currentStatus, setCurrentStatus] = useState(
    t('activity-input-upload-s3.status.awaiting-upload')
  )

  const uploadFile = async ({
    file,
    onStart = () => {},
    onSuccess,
    onError,
    fileType
  }: UploadFileProps) => {
    if (!file || !S3_BUCKET) {
      return false
    }

    setCurrentStatus(t('activity-input-upload-s3.status.uploading-file'))
    onStart?.()

    let params: UploadRequestParams | null = null

    if (file instanceof File) {
      params = generateDefaultUploadParams(file)
    } else if (fileType === 'base64') {
      params = generateBase64ImageUploadParams(file)
    } else if (fileType === 'blob') {
      params = await generateBlobVideoUploadParams(file, videoType)
    }

    if (!params) {
      return false
    }

    // parâmetros do arquivo a ser enviado para S3
    const paramsPutObjectRequest = {
      Body: params.body,
      Bucket: S3_BUCKET,
      Key: params.key,
      ...params.otherParams
    }

    // Enviando arquivo via PUT
    // https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#putObject-property
    myBucket
      .putObject(paramsPutObjectRequest)
      .on('httpUploadProgress', (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100))
      })
      .send((err) => {
        if (err) {
          setCurrentStatus(
            t('activity-input-upload-s3.status.upload-error') + err.message
          )
          onError?.(err)
        } else {
          setCurrentStatus(t('activity-input-upload-s3.status.upload-success'))
          onSuccess?.(
            URL + params.key,
            params.key,
            file instanceof File ? file.name : ''
          )
        }
      })
  }

  return {
    progress,
    setProgress,
    currentStatus,
    setCurrentStatus,
    uploadFile
  }
}

// returns up to two file extensions without the first dot (e.g.: 'tar.gz')
const handleExtension = (filename: string) => {
  return filename.match(/(\.[^.]+){1,2}/)?.[0].slice(1) || ''
}

const generateBase64ImageUploadParams = (file: string) => {
  return {
    key: uuidv4() + '.jpg',
    body: Buffer.from(file.replace(/^data:image\/\w+;base64,/, ''), 'base64'),
    otherParams: {
      ContentEncoding: 'base64',
      ContentType: 'image/jpeg'
    }
  }
}

const generateBlobVideoUploadParams = async (
  file: string,
  videoType: string
) => {
  const blob = await fetch(file).then((r) => r.blob())
  const filename = `${uuidv4()}.${videoType.replace('video/', '')}`
  return {
    key: filename,
    body: new global.File([blob], filename, {
      type: videoType,
      lastModified: Date.now()
    }),
    otherParams: {}
  }
}

const generateDefaultUploadParams = (file: File) => {
  return {
    key: `${uuidv4()}.${handleExtension(file.name)}`,
    body: file,
    otherParams: {
      ContentType: file.type
    }
  }
}

export default useUploadS3
