import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18n-lite'

import { useAuthentication } from 'authentication'

import { Button } from '@yes.technology/react-toolkit'

import { useSelectAndLinkFrameContext } from './SelectAndLinkFrameContext'

import { Container } from './SelectAndLinkFrame.styles'

const SELECT_AND_LINK_PARAM = 'select_and_link'

export const SELECT_AND_LINK_POST_MESSAGE_TYPE = 'select_and_link'

type SelectAndLinkFrameProps = React.PropsWithChildren

export default function SelectAndLinkFrame({
  children
}: SelectAndLinkFrameProps) {
  const { selectAndLinkData, setSelectAndLinkData } =
    useSelectAndLinkFrameContext()

  useEffect(() => {
    const messageHandler = ({ data }: MessageEvent) => {
      if (
        data.type === SELECT_AND_LINK_POST_MESSAGE_TYPE &&
        selectAndLinkData
      ) {
        selectAndLinkData.callback(data.values)
        setSelectAndLinkData(null)
      }
    }

    window.addEventListener('message', messageHandler)

    return () => {
      window.removeEventListener('message', messageHandler)
    }
  }, [selectAndLinkData, setSelectAndLinkData])

  const { token } = useAuthentication()

  const uniselectionString = selectAndLinkData?.uniselection
    ? '&uniselection'
    : ''

  const src = `${window.urlSiteRenderer}/${window.selectAndLinkFilterSitemodel}?token=${token}&uuid_objectclass=${selectAndLinkData?.uuidObjectclass}&${SELECT_AND_LINK_PARAM}${uniselectionString}`

  const { t } = useTranslation()

  const [height, setHeight] = useState(0)

  const adjustIframeHeight = (iframeElement: HTMLIFrameElement | null) =>
    setHeight(
      Math.max(
        iframeElement
          ? window.innerHeight - iframeElement.getBoundingClientRect().y
          : 0,
        500
      )
    )

  return (
    <Container>
      {selectAndLinkData && (
        <div>
          <Button
            variant='secondary'
            onClick={() => setSelectAndLinkData(null)}
            className='mb-3'
          >
            {t('activity-input.actions.cancel')}
          </Button>
          <iframe {...{ src, height }} width='100%' ref={adjustIframeHeight} />
        </div>
      )}
      <div>{children}</div>
    </Container>
  )
}
