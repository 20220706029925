import { datepickerToISOFormat } from '@yes.technology/react-toolkit'
import moment from 'moment'
import {
  Activity,
  ActivityGroup,
  ActivityModel,
  ActivityModelContentType,
  InteractionModel,
  InteractionModelData,
  InteractionModelItemGroup
} from 'types/shared'
import { getStepsToBeShown } from '../modules/interaction/utils/validation'
import api from './api'
import { AllowedLocales } from './date'
import { deepClone } from './helpers/object'
import validateActivityValue from './validateActivityValue'

function interactionModelItemGroupCallback(
  interactionModelItemGroup: InteractionModelItemGroup
) {
  const activityModels = interactionModelItemGroup.activity_models.map((a) => {
    return {
      uuid_activity_model: a.uuid,
      value: a.value ?? ''
    }
  })
  const itemGroup = {
    uuid_interaction_model_item_groups: interactionModelItemGroup.uuid,
    activity_models: activityModels
  }
  return itemGroup
}

function formatInteractionModelCallback(
  interactionModel: InteractionModelData
) {
  const interactionModelItemGroups =
    interactionModel.interaction_model_item_groups.map((g) => {
      return interactionModelItemGroupCallback(g)
    })

  const requestBody = {
    uuid_interaction_model: interactionModel.uuid,
    interaction_model_item_groups: interactionModelItemGroups
  }
  return requestBody
}

function addActivityGroups(
  activityGroups: ActivityModel[][],
  updatedGroups: Activity[][]
) {
  const firstActivityGroup = deepClone(activityGroups[0])

  const unaddedGroups = updatedGroups.slice(activityGroups.length)

  unaddedGroups.forEach(() => {
    activityGroups.push(
      firstActivityGroup.map((activityModel) => {
        delete activityModel.helperClass
        delete activityModel.helperText
        delete activityModel.validationStatus
        delete activityModel.value

        return {
          ...activityModel,
          uuid: activityModel.originalUuid + '-' + activityGroups.length
        }
      })
    )
  })
}

function setActivityGroupValue(activityModel: ActivityGroup, value: string) {
  const updatedGroups: Activity[][] | undefined = value && JSON.parse(value)

  if (!updatedGroups) {
    return activityModel
  }

  if (
    activityModel.activity_models.length > 0 &&
    activityModel.activity_models.length < updatedGroups.length
  ) {
    addActivityGroups(activityModel.activity_models, updatedGroups)
  }

  activityModel.activity_models.forEach((group) => {
    updatedGroups.forEach((newGroup) => {
      group.forEach((oldGroupActivityModel) => {
        newGroup.forEach((newGroupActivity) => {
          if (
            oldGroupActivityModel.uuid === newGroupActivity.uuid_activity_model
          ) {
            setActivityModelValue(oldGroupActivityModel, newGroupActivity)
          }
        })
      })
    })
  })
}

function isValidActivityModel(activityModel: ActivityModel) {
  return activityModel.validationStatus === 'valid' || !activityModel.required
}

function isValidActivityModelGroup(activityModelGroup: ActivityModel[]) {
  return activityModelGroup.every(isValidActivityModel)
}

function getGroupValidationStatus(activityModelGroup: ActivityGroup) {
  const allGroupsAreValid = activityModelGroup.activity_models.every(
    isValidActivityModelGroup
  )
  return allGroupsAreValid ? 'valid' : 'invalid'
}

const objectSelections = [
  'explicit_uniselection_objectclass',
  'explicit_multiselection_objectclass',
  'standard_multiselection',
  'standard_uniselection',
  'explicit_uniselection_accordion',
  'explicit_multiselection_accordion'
]

function isObjectSelection(contentType: ActivityModelContentType) {
  return objectSelections.includes(contentType)
}

function isContentTypeObject(activityModel: ActivityModel) {
  return (
    activityModel?.information_request_filteraction ||
    isObjectSelection(activityModel.content_type)
  )
}

function setActivityModelValue(
  activityModel: ActivityModel,
  activity: Activity
) {
  const isActivityGroup = activityModel.content_type === 'activity_group'

  if (isActivityGroup) {
    setActivityGroupValue(activityModel, activity.des)
    activityModel.validationStatus = getGroupValidationStatus(activityModel)
    return
  }

  const activityValue = activity.des
    ? { id: activity.des, des: activity.complement || activity.des }
    : ''

  const contentIsObject = isContentTypeObject(activityModel)

  activityModel.value = contentIsObject ? activityValue : activity.des

  const { validationStatus, helperText } = validateActivityValue({
    activityModel
  })

  activityModel.validationStatus = validationStatus
  activityModel.helperText = helperText
}

export function setInteractionModelActivities(
  interactionModel: InteractionModelData,
  activities: Activity[]
) {
  const interactionModelClone = deepClone(interactionModel)
  const steps = interactionModelClone.interaction_model_item_groups

  activities.forEach((activity) => {
    steps?.forEach((step) => {
      const newActivityModels: ActivityModel[] = JSON.parse(
        JSON.stringify(step.activity_models)
      )
      newActivityModels.forEach((activityModel) => {
        if (
          activityModel.uuid === activity.uuid_activity_model &&
          activityModel.value !== activity.des
        ) {
          setActivityModelValue(activityModel, activity)
        }
      })
      step.activity_models = newActivityModels
    })
  })

  return {
    ...interactionModelClone,
    interaction_model_item_groups: steps
  }
}

function getActivityFromActivityGroup(activityModel: ActivityGroup) {
  const groups = activityModel.activity_models

  const groupsActivities = groups.map((group) => {
    const activities = group.map(getActivityFromActivityModel)
    const nonEmptyActivities = activities.filter((activity) => activity.des)
    return nonEmptyActivities
  })

  const nonEmptyGroups = groupsActivities.filter((group) => group.length > 0)

  const activityValue =
    nonEmptyGroups.length > 0 ? JSON.stringify(nonEmptyGroups) : ''

  return {
    uuid_activity_model: activityModel.uuid,
    des: activityValue
  }
}

export function getActivityFromActivityModel(
  activityModel: ActivityModel
): Activity {
  const isActivityGroup = activityModel.content_type === 'activity_group'

  if (isActivityGroup) {
    return getActivityFromActivityGroup(activityModel)
  }

  const contentIsObject = isContentTypeObject(activityModel)

  const activityModelValue = activityModel.value
  const isValueObject = typeof activityModelValue === 'object'

  const uuid = isValueObject ? activityModelValue.id : ''

  const description = isValueObject
    ? activityModelValue.des
    : activityModelValue || ''

  return {
    uuid_activity_model: activityModel.uuid,
    des: contentIsObject ? uuid : description,
    ...(contentIsObject && { complement: description })
  }
}

export function getActivitiesFromInteractionModel(
  interactionModel: InteractionModelData
) {
  const activities: Activity[] = []

  const steps = interactionModel.interaction_model_item_groups

  if (!steps) {
    return activities
  }

  getStepsToBeShown(steps).forEach((step: InteractionModelItemGroup) => {
    const stepActivities = step.activity_models.map(
      getActivityFromActivityModel
    )

    const nonEmptyActivities = stepActivities.filter((object) => object.des)
    activities.push(...nonEmptyActivities)
  })

  return activities
}

// Retorna os uuids das activityModels do tipo especificado
export function getUuidsForContentType(
  steps: InteractionModelItemGroup[],
  contentType: ActivityModelContentType
) {
  const allActivityModels = steps
    .map((step) => {
      return step.activity_models.map((activityModel) => activityModel)
    })
    .flat()

  const activityModelsWithContentType = allActivityModels.filter(
    (activityModel) => activityModel.content_type === contentType
  )

  const uuids = activityModelsWithContentType.map(
    (activityModel) => activityModel.uuid
  )

  return uuids
}

// Troca o conteúdo do campo "des" pelo uuid do documento (document - objectclass)
export function handleDocumentPhoto(
  activities: Activity[],
  itemGroups: InteractionModelItemGroup[]
) {
  const uuids = getUuidsForContentType(itemGroups, 'document_photo')
  if (uuids.length > 0) {
    uuids.forEach((uuid) => {
      activities.find((activity, index) => {
        if (activity.uuid_activity_model === uuid) {
          const des = JSON.parse(activity.des)
          activities[index] = {
            ...activity,
            des: des.uuid
          }
        }
      })
    })
  }
  return activities
}

export function handleDate(
  activities: Activity[],
  itemGroups: InteractionModelItemGroup[],
  language: string
) {
  const dateUuids = getUuidsForContentType(itemGroups, 'date')
  const dateTimeUuids = getUuidsForContentType(itemGroups, 'datetime')
  const uuids = [...dateUuids, ...dateTimeUuids]

  if (!uuids.length) {
    return activities
  }

  return activities.map((activity) => {
    if (uuids.includes(activity.uuid_activity_model)) {
      return {
        ...activity,
        des: datepickerToISOFormat(activity.des, language as AllowedLocales)
      }
    }
    return activity
  }) as Activity[]
}

export function formatInteractionPost(
  interactionModel: InteractionModel,
  language: string
) {
  const interactionModelData = interactionModel.data

  if (!interactionModelData) {
    return
  }

  let activities = getActivitiesFromInteractionModel(interactionModelData)

  const steps = interactionModelData.interaction_model_item_groups

  if (!steps) {
    return
  }

  /*
    Tratamento especial para a activity model "document_photo";
    Deve manipular as activities trocando o conteúdo do campo "des"
    pelo uuid do documento (document - objectclass)
  */
  activities = handleDocumentPhoto(activities, steps)

  activities = handleDate(activities, steps, language)

  // coords em string
  const latitude = interactionModel.geolocation?.latitude?.toString() || ''
  const longitude = interactionModel.geolocation?.longitude?.toString() || ''

  const workflowUuid = interactionModel.workflowUuid && {
    uuid_workflow: interactionModel.workflowUuid
  }

  const body = [
    {
      ...workflowUuid,
      uuid_interaction_model: interactionModelData.uuid,
      uuid_user_creation_row: interactionModel.user,
      uuid_user_modification_row: interactionModel.user,
      uuid_language: interactionModel.language,
      latitude: latitude,
      longitude: longitude,
      date_start_interaction: interactionModel.dateStartInteraction,
      date_end_interaction: moment().format(),
      activities
    }
  ]

  return body
}

export async function validateInteractionModelCallback(
  interactionModel: InteractionModelData,
  endpoint: string
) {
  const formatedInteractionModel =
    formatInteractionModelCallback(interactionModel)
  const res = await api.postInputValidation(
    formatedInteractionModel,
    endpoint,
    true
  )
  return res
}

export async function validateInteractionModelItemGroupCallback(
  interactionModelItemGroup: InteractionModelItemGroup,
  endpoint: string
) {
  const formatedInteractionModelItemGroup = interactionModelItemGroupCallback(
    interactionModelItemGroup
  )
  const res = await api.postInputValidation(
    formatedInteractionModelItemGroup,
    endpoint,
    true
  )
  return res
}

export async function saveInteraction(
  interactionModel: InteractionModel,
  language: string
) {
  const body = formatInteractionPost(interactionModel, language)
  const res = await api.postSaveInteraction(body, true)
  return res
}

type RemoveActivityGroupParams = {
  interactionModel: InteractionModelData
  activityModelUuid: string
  removedGroupIndex: number
}

export function removeActivityGroup({
  interactionModel,
  activityModelUuid,
  removedGroupIndex
}: RemoveActivityGroupParams) {
  const steps = interactionModel.interaction_model_item_groups

  steps.forEach((step) => {
    step.activity_models.forEach((activityModel) => {
      if (
        activityModel.uuid === activityModelUuid &&
        activityModel.content_type === 'activity_group'
      ) {
        activityModel.activity_models.splice(removedGroupIndex, 1)

        activityModel.activity_models.forEach((group, index) => {
          group.forEach((activityModel) => {
            activityModel.uuid = activityModel.originalUuid + `-${index}`
          })
        })

        activityModel.validationStatus = getGroupValidationStatus(activityModel)
      }
    })
  })

  return interactionModel
}

type AddActivityGroupParams = {
  interactionModel: InteractionModelData
  activityModelUuid: string
  newActivityGroup: ActivityModel[]
}

export function addActivityGroup({
  interactionModel,
  activityModelUuid,
  newActivityGroup
}: AddActivityGroupParams) {
  const steps = interactionModel.interaction_model_item_groups

  steps.forEach((step) => {
    step.activity_models.forEach((activityModel) => {
      if (
        activityModel.uuid === activityModelUuid &&
        activityModel.content_type === 'activity_group'
      ) {
        activityModel.activity_models.push(newActivityGroup)
      }
    })
  })

  return interactionModel
}

type ActivityModelsByUuid = { [key: string]: ActivityModel }

const uuidReducer = (
  uuidMap: ActivityModelsByUuid,
  current: ActivityModel
): ActivityModelsByUuid => {
  return {
    ...uuidMap,
    [current.uuid]: current,
    ...(current.content_type === 'activity_group'
      ? current.activity_models.flat().reduce(uuidReducer, {})
      : {})
  }
}

export function objectifyActivityModelsByUuid(
  interactionModel: InteractionModel
) {
  const steps = interactionModel?.data?.interaction_model_item_groups || []
  const activityModels = steps.map((step) => step.activity_models).flat()

  return activityModels.reduce(uuidReducer, {})
}
