import { InteractionModelItemGroup } from 'types/shared'
import { isActivityModelInvalid, getStepsToBeShown } from '../utils/validation'

const useNavigation = (steps?: InteractionModelItemGroup[]) => {
  const validatedInteraction =
    getStepsToBeShown(steps)
      .map((itemGroup) =>
        itemGroup.activity_models.filter(isActivityModelInvalid)
      )
      .flat().length === 0
  return {
    showDoubleArrowhead: validatedInteraction
  }
}

export default useNavigation
