import { formatDateTime } from '@yes.technology/react-toolkit'
import {
  Activity,
  ActivityModelContentType,
  InteractionModelData
} from 'types/shared'
import { AllowedLocales } from './date'

type Formatter = (activity: Activity, language: string) => Activity

const defaultFormatter: Formatter = (activity) => activity

const dateFormatter: Formatter = (activity: Activity, language) => {
  try {
    const formatted = {
      ...activity,
      des: formatDateTime(
        new Date(activity.des),
        language as AllowedLocales,
        'date'
      )
    }
    return formatted
  } catch (error) {
    return activity
  }
}

const dateTimeFormatter: Formatter = (activity: Activity, language) => {
  try {
    return {
      ...activity,
      des: formatDateTime(
        new Date(activity.des),
        language as AllowedLocales,
        'date-time'
      )
    }
  } catch (error) {
    return activity
  }
}

type Formatters = {
  [key in ActivityModelContentType]?: Formatter
}

const formatters: Formatters = {
  date: dateFormatter,
  datetime: dateTimeFormatter
}

const findModelFromActivity = (
  activity: Activity,
  interactionModelData: InteractionModelData
) => {
  const flatActivityModels = interactionModelData.interaction_model_item_groups
    .map((step) => step.activity_models)
    .flat()

  return flatActivityModels.find(
    (model) => model.uuid === activity.uuid_activity_model
  )
}

export default function formatBlueprintsResponseActivities(
  activities: Activity[],
  interactionModelData: InteractionModelData,
  language: string
): Activity[] {
  return activities.map((activity) => {
    const activityModel = findModelFromActivity(activity, interactionModelData)

    if (!activityModel) {
      return activity
    }

    const formatter = formatters[activityModel.content_type] || defaultFormatter

    return formatter(activity, language)
  })
}
