import { CanvasHTMLAttributes, useEffect, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { useTranslation } from 'react-i18n-lite'

import { Button, Icon, Text } from '@yes.technology/react-toolkit'

import 'asset/css/form.css'
import { ActivityInputProps } from '../Activity.types'
import {
  Agreement,
  CanvasContainer,
  Container,
  ImageContainer,
  LabelArea
} from './ActivityInputDraw.styles'
import { ActivityModelDraw } from 'types/shared'
import { useResizeDetector } from 'react-resize-detector'
import { SafeHtmlContent } from 'modules/shared/SafeHtmlContent'

export type ActivityInputDrawProps = {
  explanation?: string
  label?: string
  readOnly?: boolean
  inputValue: string

  onChange: ActivityInputProps['handleChange']
} & Pick<ActivityModelDraw, 'agreement'>

function ActivityInputDraw({
  explanation,
  inputValue,
  label,
  agreement,
  readOnly,

  onChange
}: ActivityInputDrawProps) {
  const { t } = useTranslation()
  const canvasRef = useRef<SignatureCanvas>(null)

  const { current: initialImageData } = useRef(inputValue)

  useEffect(() => {
    if (readOnly || !initialImageData) {
      return
    }

    const memoryImage = new Image()
    memoryImage.src = initialImageData

    memoryImage.onload = () => {
      if (!canvasRef.current) {
        return
      }

      const originalDimension = [memoryImage.width, memoryImage.height]
      const canvasDimension = [
        canvasRef.current.getCanvas().offsetWidth,
        canvasRef.current.getCanvas().offsetHeight
      ]

      const scaleFactor = Math.min(
        canvasDimension[0] / originalDimension[0],
        canvasDimension[1] / originalDimension[1],
        1
      )

      const scaledDimension = [
        Math.floor(originalDimension[0] * scaleFactor),
        Math.floor(originalDimension[1] * scaleFactor)
      ]

      canvasRef.current.fromDataURL(initialImageData, {
        width: scaledDimension[0],
        height: scaledDimension[1]
      })
    }
  }, [initialImageData, readOnly])

  const handleSave = () => {
    const canvasImageData =
      canvasRef.current?.getCanvas().toDataURL('image/png') || ''

    onChange(canvasImageData)
  }

  const handleClear = () => {
    canvasRef.current?.clear()

    onChange('')
  }

  const [justResized, setJustResized] = useState<boolean>()
  const { ref: canvasContainerRef } = useResizeDetector<HTMLDivElement>({
    onResize: () =>
      // `justResized` is set as `true` only when size changes, not at initilization
      setJustResized((prevJustResized) => prevJustResized === false)
  })

  if (justResized && inputValue) {
    setJustResized(false)
    handleClear()
  }

  return (
    <Container>
      {explanation && (
        <Text variant='content-04' as='div'>
          {explanation}
        </Text>
      )}

      {agreement && (
        <Agreement>
          <SafeHtmlContent html={agreement} />
        </Agreement>
      )}

      <div>
        <LabelArea>
          <Text variant='label-required-03' as='label'>
            {label}
          </Text>
          {!readOnly && (
            <Button
              variant='secondary'
              onClick={handleClear}
              title={t('activity-input-draw.clear')}
              disabled={!inputValue}
              className='mx-auto'
            >
              <Icon iconName='Cleaner' />
            </Button>
          )}
        </LabelArea>

        {inputValue && readOnly && (
          <ImageContainer>
            <img src={inputValue} data-testid='signature-img' />
          </ImageContainer>
        )}

        {!readOnly && (
          <CanvasContainer ref={canvasContainerRef}>
            <SignatureCanvas
              onBegin={() => setJustResized(false)}
              onEnd={handleSave}
              ref={canvasRef}
              clearOnResize={false}
              canvasProps={
                {
                  'data-testid': 'signature-canvas'
                } as CanvasHTMLAttributes<HTMLCanvasElement>
              }
            />
          </CanvasContainer>
        )}
      </div>
    </Container>
  )
}

export default ActivityInputDraw
