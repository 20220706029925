export default function updateActivityModelStatus({
  interactionModel,
  activityModelUuid,
  validationStatus,
  helperText
}) {
  const steps = interactionModel?.interaction_model_item_groups || []

  steps.forEach((step) => {
    step.activity_models.forEach((activityModel) => {
      if (activityModel.uuid === activityModelUuid) {
        activityModel.validationStatus = validationStatus
        activityModel.helperText = helperText
      }
    })
  })

  return interactionModel
}
