import { useTranslation } from 'react-i18n-lite'
// import CheckIcon from '../../../asset/img/loading-balls-circle-blue.svg'
import success from '../../../asset/img/success.svg'
import { DocumentListCard, Text } from '@yes.technology/react-toolkit'

const Success = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className='pt-3'>
        <DocumentListCard
          spacingClass='doubleXLarge'
          className='container d-flex flex-column align-items-center gap-4'
        >
          <img src={success} alt='icon success' />

          <Text as='h1' variant='content-emphasis-06' className='text-center'>
            {t('step.success.des')}
          </Text>
        </DocumentListCard>
      </div>
    </>
  )
}

export default Success
