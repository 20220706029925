import { StandardMultiselection } from '@yes.technology/react-toolkit'
import useFetchSuggestions from '../../../hooks/useFetchSuggestions'
import { useEffect } from 'react'
import { ActivityModel } from 'types/shared'
import { ActivityInputProps } from '../Activity.types'
import { FilteractionParams } from 'utils/apiTypes'

export type ActivityStandardMultiselectionProps = {
  id: string
  label: string
  value: ActivityModel['value']
  filteraction?: string
  filteractionParams?: FilteractionParams[]
  onChange: ActivityInputProps['handleChange']
  options?: { uuid: string; des: string }[]
} & Pick<
  React.ComponentProps<typeof StandardMultiselection>,
  'id' | 'label' | 'required' | 'statusClass' | 'placeholder'
>

const ActivityStandardMultiselection = ({
  id,
  label,
  value,
  filteraction,
  filteractionParams,
  options = [],
  onChange,
  required,
  statusClass,
  placeholder
}: ActivityStandardMultiselectionProps) => {
  const formattedValue = {
    des: (typeof value === 'object' && value?.des?.split(', ')) || [],
    id: (typeof value === 'object' && value?.id?.split(', ')) || []
  }

  const selectedOptions: { id: string; value: string }[] =
    formattedValue.id.map((id, index) => ({
      id,
      value: formattedValue.des[index]
    }))

  const { suggestions, fetchSuggestions, isLoading } = useFetchSuggestions({
    id,
    filteraction,
    filteractionParams
  })

  useEffect(() => {
    if (!filteraction) {
      return
    }

    fetchSuggestions('.*')
  }, [fetchSuggestions, filteraction])

  const finalOptions = options.length ? options : suggestions

  const handleChange = ({ id, value }: { id: string; value: string }) => {
    const optionIndex = selectedOptions.findIndex((option) => option.id === id)
    const newSelectedOptions = [...selectedOptions]

    if (optionIndex === -1) {
      newSelectedOptions.push({ id, value })
    } else {
      const index = selectedOptions.findIndex((option) => option.id === id)
      newSelectedOptions.splice(index, 1)
    }

    onChange({
      id: newSelectedOptions.map((option) => option.id).join(', '),
      des: newSelectedOptions.map((option) => option.value).join(', ')
    })
  }

  const loadingStatusClass = isLoading ? 'processing' : undefined

  return (
    <StandardMultiselection
      id={id}
      label={label}
      heightClass='medium'
      options={finalOptions.map((rawOption) => ({
        id: rawOption.uuid,
        value: rawOption.des
      }))}
      selectedOptions={selectedOptions}
      required={required}
      onSelect={handleChange}
      statusClass={loadingStatusClass || statusClass}
      placeholder={placeholder}
    />
  )
}

export default ActivityStandardMultiselection
