import { Button, DataField, Icon } from '@yes.technology/react-toolkit'
import { useReducer, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18n-lite'
import { useJsApiLoader } from '@react-google-maps/api'

import { objectifyActivityModelsByUuid } from '../../../../../utils/interactionModel'
import { useCalcUserAddressFromLatLong } from '../../../hooks/useCalcUserAddressFromLatLong'
import useUpdateGeolocation from './useUpdateGeolocation'
import useAddressFromModel from './useAddressFromModel'
import GeolocationModal from 'modules/shared/GeolocationModal/GeolocationModal'
import useGeocodeCallback from 'modules/shared/hooks/useGeocodeCallback'

export default function ActivityInputGeolocation({
  id,
  label,
  required,
  value = '',
  dependencies,
  onChange,
  currentIndexActivityGroup,
  fillGeolocationValues
}) {
  const [isModalOpen, toggleModal] = useReducer((state) => !state, false)
  const disableGeolocationProcessing = useRef(false)
  const { t } = useTranslation()

  const { googleMapsApiKey } = window
  const { isLoaded } = useJsApiLoader({ googleMapsApiKey })

  const interactionModel = useSelector(
    ({ interactions: { interactionModel } }) => interactionModel
  )
  const activitiesByUuid = objectifyActivityModelsByUuid(interactionModel)
  const address = useAddressFromModel({
    activitiesByUuid,
    dependencies,
    currentIndexActivityGroup
  })

  const coord = interactionModel?.geolocation

  const { processUserAddressFromLatLong } = useCalcUserAddressFromLatLong(
    dependencies,
    coord
  )

  const geocodeCallback = useGeocodeCallback()
  useUpdateGeolocation({ address, geocodeCallback, onChange, value })

  if (
    fillGeolocationValues &&
    !disableGeolocationProcessing.current &&
    isLoaded &&
    !!coord
  ) {
    disableGeolocationProcessing.current = true

    processUserAddressFromLatLong()
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'flex-end',
        gap: '4px'
      }}
    >
      <DataField
        spacingClass='medium'
        informational={true}
        {...{ onChange, value, id, required, label }}
      />
      <Button
        variant='secondary'
        onClick={toggleModal}
        aria-label={t('activity-input-geolocation.labels.set')}
        disabled={!value}
      >
        <Icon iconName='Pin' />
      </Button>
      <GeolocationModal
        open={isModalOpen}
        handleClose={toggleModal}
        {...{ id, value, onChange }}
      />
    </div>
  )
}
