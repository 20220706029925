import { feedbackcard } from '@yes.technology/react-toolkit'

import { VALIDATION_STATUSES } from '../../../utils/constants'
import {
  ActivityGroup,
  ActivityModel,
  InteractionModelItemGroup,
  Translate
} from 'types/shared'

export const isActivityModelInvalid = (activityModel: ActivityModel) => {
  const { required, validationStatus } = activityModel

  return (
    validationStatus === VALIDATION_STATUSES.INVALID ||
    (required && validationStatus !== VALIDATION_STATUSES.VALID)
  )
}

const getInvalidActivityModel = (activityModel: ActivityModel) => {
  const isInvalid = isActivityModelInvalid(activityModel)

  if (isInvalid) {
    activityModel.validationStatus = VALIDATION_STATUSES.INVALID
  }
  return isInvalid
}

// valida activity_models obrigatórias por step
export const getInvalidFieldsInStep = (
  itemGroup: InteractionModelItemGroup
) => {
  return itemGroup.activity_models.filter(getInvalidActivityModel)
}

export const showMessageRequiredFields = (
  invalidFields: ActivityModel[],
  t: Translate
) => {
  if (invalidFields.length) {
    let errorMessage = t('step.validation.message-all-required-fields')
    if (invalidFields.length === 1) {
      errorMessage = t('step.validation.message-is-required-field', {
        field: invalidFields[0].display_text.des
      })
    }
    showError(errorMessage)
    return false
  }
  return true
}

export const showError = (title: string, message?: string) => {
  if (!title) return false

  feedbackcard(title, {
    type: 'error',
    message
  })
}

export const getStepsToBeShown = (
  itemGroups: InteractionModelItemGroup[] = []
) => itemGroups.filter((itemGroup) => itemGroup?.show !== false)

const shouldValidateGroup = (
  activityModelGroup: ActivityModel[],
  required: boolean
) => {
  const groupHasSomeValue = activityModelGroup.some(
    (activityModel) => activityModel.value
  )

  return required || groupHasSomeValue
}

const validateActivityModelGroups = (
  activityModelGroups: ActivityModel[][],
  required: boolean = false
) => {
  const invalidActivityModelGroups: ActivityModel[][] = []

  activityModelGroups.forEach((activityModelGroup, groupIndex) => {
    if (!shouldValidateGroup(activityModelGroup, required)) {
      return
    }

    activityModelGroup.forEach((activityModel) => {
      if (!isActivityModelInvalid(activityModel)) {
        return
      }

      if (!invalidActivityModelGroups[groupIndex]) {
        invalidActivityModelGroups[groupIndex] = [
          {
            ...activityModel,
            validationStatus: VALIDATION_STATUSES.INVALID
          }
        ]
      } else {
        invalidActivityModelGroups[groupIndex].push({
          ...activityModel,
          validationStatus: VALIDATION_STATUSES.INVALID
        })
      }
    })
  })

  return invalidActivityModelGroups
}

export const getInvalidActivityModelsByItemGroup = (
  itemGroup: InteractionModelItemGroup,
  t: Translate
) => {
  const listInvalidActivityModels: ActivityModel[] = []

  for (const activityModel of itemGroup.activity_models) {
    if (activityModel.content_type === 'activity_group') {
      const validatedActivityModelGroups = validateActivityModelGroups(
        activityModel.activity_models,
        activityModel.required
      )
      if (validatedActivityModelGroups.length) {
        listInvalidActivityModels.push({
          ...activityModel,
          activity_models: validatedActivityModelGroups
        })
      }
    } else if (activityModel.content_type === 'agreement_term') {
      if (isActivityModelInvalid(activityModel)) {
        listInvalidActivityModels.push({
          error_text: {
            des: itemGroup.des
          },
          ...activityModel,
          validationStatus: VALIDATION_STATUSES.INVALID
        })
      }
    } else {
      if (isActivityModelInvalid(activityModel)) {
        listInvalidActivityModels.push({
          ...activityModel,
          validationStatus: VALIDATION_STATUSES.INVALID
        })
      }
    }
  }

  showRequiredMessageActivityModels(listInvalidActivityModels, t)

  return listInvalidActivityModels
}

const activityModelMatchesRegex = (activityModel: ActivityModel) => {
  if (
    activityModel.content_type !== 'string' ||
    !activityModel.validation_regex
  ) {
    return true
  }

  return new RegExp(activityModel.validation_regex).test(
    String(activityModel.value)
  )
}

export const showRequiredMessageActivityModels = (
  invalidActivityModels: ActivityModel[],
  t: Translate,
  activityModelGroupIndex?: number
) => {
  if (!invalidActivityModels.length) return true

  let errorMessage = ''

  if (invalidActivityModels.length > 1) {
    errorMessage = t('step.validation.message-all-required-fields')
  }

  const [invalidActivityModel] = invalidActivityModels
  const isGroup = invalidActivityModel.content_type === 'activity_group'

  if (isGroup) {
    invalidActivityModel.activity_models.map((activityModelGroups, index) =>
      showRequiredMessageActivityModels(activityModelGroups, t, index)
    )
  } else if (invalidActivityModels.length === 1) {
    const hasInvalidFormat = !activityModelMatchesRegex(invalidActivityModel)

    if (activityModelGroupIndex !== undefined && activityModelGroupIndex >= 0) {
      errorMessage = t(
        `${
          hasInvalidFormat
            ? 'step.validation.message-invalid-format-in-activity-group'
            : 'step.validation.message-is-required-field-in-activity-group'
        }`,
        {
          field: invalidActivityModel.display_text.des,
          groupNumber: String(activityModelGroupIndex + 1)
        }
      )
    } else if (invalidActivityModel.content_type === 'agreement_term') {
      errorMessage = invalidActivityModel.error_text?.des || ''
    } else {
      errorMessage = t(
        `${
          hasInvalidFormat
            ? 'step.validation.message-invalid-format'
            : 'step.validation.message-is-required-field'
        }`,
        {
          field: invalidActivityModel.display_text.des
        }
      )
    }
  }

  showError(errorMessage)
}

const findActivityModelByUuid = (
  invalidActivityModels: ActivityModel[],
  uuid: string
) =>
  invalidActivityModels?.find(
    (invalidActivityModel) => invalidActivityModel.uuid === uuid
  )

export const updateStepsWithInvalidActivities = (
  steps: InteractionModelItemGroup[],
  currentStep: InteractionModelItemGroup,
  invalidActivityModels: ActivityModel[]
) => {
  const currentGroupIndex = steps.findIndex(
    (interactionModelItemGroup) =>
      interactionModelItemGroup.uuid === currentStep.uuid
  )

  const updatedSteps = [...steps]

  updatedSteps[currentGroupIndex] = {
    ...currentStep,
    activity_models: currentStep.activity_models.map(
      (oldActivityModel, oldActivityModelIndex) => {
        if (oldActivityModel.content_type === 'activity_group') {
          return {
            ...oldActivityModel,
            activity_models: oldActivityModel.activity_models.map(
              (activityModelGroup, activityModelGroupIndex) =>
                activityModelGroup.map((oldActivityModel) => {
                  const invalidActivtyModelGroup = invalidActivityModels[
                    oldActivityModelIndex
                  ] as ActivityGroup

                  const updatedActivityModel = findActivityModelByUuid(
                    invalidActivtyModelGroup?.activity_models?.[
                      activityModelGroupIndex
                    ],
                    oldActivityModel.uuid
                  )

                  return updatedActivityModel || oldActivityModel
                })
            )
          }
        } else {
          const updatedActivityModel = findActivityModelByUuid(
            invalidActivityModels,
            oldActivityModel.uuid
          )

          return updatedActivityModel || oldActivityModel
        }
      }
    )
  }

  return updatedSteps
}
