import { Button, feedbackcard, Icon } from '@yes.technology/react-toolkit'
import { useRef } from 'react'
import { useTranslation } from 'react-i18n-lite'
import { formatFileSize } from '../utils/formatFileSize'

interface FileUploaderProps {
  onChange?: (file: File) => void
  accept?: string
  maxSizeInBytes?: number
}

const FileUploader = ({
  onChange,
  accept = '*',
  maxSizeInBytes
}: FileUploaderProps) => {
  const { t } = useTranslation()
  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    hiddenFileInput.current?.click()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files?.[0]

    if (fileUploaded) {
      if (maxSizeInBytes && fileUploaded.size > maxSizeInBytes) {
        feedbackcard(t('file-uploader.exceeds-limit-message.title'), {
          message: t('file-uploader.exceeds-limit-message.message', {
            maxSizeInBytes: formatFileSize(maxSizeInBytes)
          }),
          type: 'error'
        })
        return
      }

      onChange?.(fileUploaded)
    }
  }

  return (
    <>
      <input
        type='file'
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        accept={accept}
        data-testid='file-input'
      />
      <Button
        onClick={handleClick}
        style={{ width: '40px', marginLeft: '4px' }}
      >
        <Icon iconName='DevicesLocal' />
      </Button>
    </>
  )
}

export default FileUploader
