import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { interactionModelOperations } from '../redux'
import { useAppDispatch, useAppSelector } from 'state/store'

type UrlParams = {
  id_interaction_model: string
}

type UseInteractionModelParams = {
  currentTime: string
}

const useInteractionModel = ({ currentTime }: UseInteractionModelParams) => {
  const dispatch = useAppDispatch()
  const { id_interaction_model: idInteractionModel } = useParams<UrlParams>()

  const getInteractionModel = useCallback(() => {
    dispatch(interactionModelOperations.getInteractionModel(idInteractionModel))
  }, [dispatch, idInteractionModel])

  const {
    interactionModel,
    interactionModel: { error }
  } = useAppSelector(({ interactions }) => interactions)

  useEffect(() => {
    const interactionModelIdChanged =
      idInteractionModel !== interactionModel?.data?.uuid

    if (interactionModelIdChanged) {
      getInteractionModel()
    }
  }, [getInteractionModel, idInteractionModel, interactionModel?.data?.uuid])

  useEffect(() => {
    if (interactionModel.interactionFinished) {
      dispatch(
        interactionModelOperations.setConfigToNewInteraction(currentTime)
      )
    }
  }, [interactionModel, currentTime, dispatch])

  return {
    interactionModel,
    interactionModelError: error,
    getInteractionModel,
    isFetchingInteractionModel: interactionModel?.isFetching
  }
}

export default useInteractionModel
